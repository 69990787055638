.VideosWatch {
  max-width: 60em;
  margin: 0 auto;
  padding: 1em;
  .video-card {
    background-color: #fff;
    display: flex;
    flex-flow: column;
    box-shadow: 0 1px 5px #eee;
    .video-title {
      font-size: 1.25em;
      p {
        margin: 0.5em;
      }
    }
    .video-js {
      width: 100%;
      height: auto;
    }
    video {
      position: relative;
      width: 100%;
    }
    .video-details {
      padding: 0 1em;
      .video-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .download img {
          vertical-align: middle;
          width: 1.75em;
          height: 1.75em;
        }
      }
    }
  }
  .Loader {
    height: 80vh;
  }
}
.App .hls-toggle-link {
  text-align: center;
  margin: 0;
  margin-bottom: 1em;
  font-size: 1em;
  a {
    color: #3c739b;
  }
}

.App {
  a {
    text-decoration: none;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #444;
    }
  }
  .btn {
    cursor: pointer;
    background-color: #2196f3;
    color: #fff;
    padding: 0.5em;
    border-color: #2196f3;
    &:hover {
      background-color: #1186e3;
    }
    &.danger {
      background-color: #da1717;
      border-color: #da1717;
      &:hover {
        background-color: #c01212;
        border-color: #c01212;
      }
    }
    &.btn-lg {
      font-size: 1em;
      font-weight: bold;
      padding: 0.5em 1em;
    }
  }
}

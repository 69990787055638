.VideosListCard {
  max-width: 33%;
  @media (max-width: 920px) {
    max-width: 50%;
  }
  @media (max-width: 600px) {
    max-width: 100%;
  }
  .card {
    margin: 1em;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    box-shadow: 0 1px 5px #eee;
    position: relative;
    .card-image {
      position: relative;
      display: inline-block;
      img {
        width: 100%;
        height: 12em;
        object-fit: cover;
      }
      .progress {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .react-sweet-progress-symbol {
          color: #fff;
        }
        h3,
        p {
          color: #fff;
          text-align: center;
        }
        .Loader {
          position: relative;
        }
        .Loader .outer,
        .Loader .middle,
        .Loader .inner {
          border-top-color: #fff;
          border-right-color: #fff;
        }
      }
    }
    .card-info {
      display: flex;
      flex-flow: column;
      border-bottom: 1px solid #eee;
      padding: 0.5em;
      input {
        border: 1px solid transparent;
        font-size: 1.1em;
        padding: 0.5em 0.25em;
        margin-left: -0.25em;
        &:hover {
          border: 1px solid #e4e4e4;
        }
        &:focus {
          border: 1px solid #e4e4e4;
          background-color: #f4f4f4;
        }
      }
      a {
        color: #007bff;
        font-size: 0.9em;
      }
      .hls-status-text {
        font-size: 1em;
        color: #13991f;
        position: absolute;
        top: 0em;
        right: 1em;
        font-weight: bold;
      }
    }
    .card-meta {
      padding: 0 0.5em;
      font-size: 0.9em;
      background-color: #f9f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        margin: 0.5em 0;
      }
    }
  }
}

.NavBar {
  background-color: #fff;
  padding: 0.75em 1em;
  border-bottom: 1px solid #efefef;
  display: flex;
  justify-content: space-between;
  button {
    border: none;
    background-color: transparent;
    font-size: 1em;
    color: #000;
    cursor: pointer;
    &:hover {
      color: #444;
    }
  }
}

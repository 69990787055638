.SignInForm,
.SignUpForm {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  form {
    max-width: 15em;
    background-color: #fff;
    box-shadow: 0 1px 5px #eee;
    border-radius: 0.5em;
    padding: 2em 1.5em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    .form-group {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }
    h2 {
      margin-top: 0;
      margin-bottom: 1em;
    }
    label {
      margin-bottom: 0.25em;
    }
    input {
      margin-bottom: 1em;
      font-size: 1.1em;
      padding: 0.25em;
      border: 1px solid #cecece;
      &:focus {
        background-color: #f1f1f1;
      }
    }
    button {
      margin-top: 0.5em;
      margin-bottom: 1.5em;
    }
    a {
      text-decoration: underline;
      margin-bottom: -0.5em;
    }
    .error {
      color: rgb(216, 49, 49);
      margin-top: 0;
      label {
        font-weight: bold;
      }
      input {
        border-color: rgb(216, 49, 49);
      }
      .errorMessage {
        margin-top: -0.5em;
        margin-bottom: 1.5em;
      }
    }
  }
}

@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Regular.ttf");
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/OpenSans-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #f1f1f1;
}

.VideosList {
  max-width: 80em;
  margin: 0 auto;
  padding: 0 1em;
  .videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .Loader {
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  .empty {
    margin-top: 2em;
    text-align: center;
  }
  .load-more-btn {
    display: block;
    margin: 0 auto;
    margin-bottom: 1em;
    font-size: 0.9em;
  }
}

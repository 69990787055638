.VideoUpload {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;
  .hidden {
    display: none;
  }
  button {
    font-size: 1em;
  }
}
